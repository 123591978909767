import PushServerClient from "pushserver-client/dist/pushServerClient";
class socket {

    constructor() {
        // STATIC CLASS
    }

    static checkWebSocket() {
        return ("WebSocket" in window && !("__addTask" in WebSocket)) ||
            "MozWebSocket" in window;
    }

    static checkSocketConnection(options) {
        Login.client = new PushServerClient({
            alternatePort: 1030,
            autoReconnect: false,
            configPath: options.configPath,
            cachingClusterURL: options.cachingClusterURL,
            connectTimeout: 5000,
            standartPort: 80,
            useCurrentHost: options.cachingClusterURL === undefined,
            onConnected: options.OnConnected
        });

        Login.client.on("connected", options.OnConnected);

        window.setTimeout(function () {
            if (!Login.connected && options.OnFailure) {
                options.OnFailure();
            }
        }, 10000);
    }
}

export default socket;